export default {
  proxy_path: "https://b7odona0aa.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://fxe384rryg.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyAL4HCOqiH0J_AHjY5H5Xr3_XzfGyTSQ_g",
  main_company_term_id: "6",
  crm_base_url: "https://crm.nsr.forwoodsafety.com",
  forwood_id_url: "https://id.nsr.forwoodsafety.com?redirect_uri=https://mapreport.nsr.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.nsr.forwoodsafety.com",
  Auth: {
    userPoolId: "ap-southeast-2_6nWmGigyH",
    userPoolWebClientId: "1mpcnqjqg0f4kim25l39mpgnpd",
    cookieStorage: {
      domain: ".nsr.forwoodsafety.com",
      secure: true
    }
  }
};
